// src/components/IMTSQRCode.js
/*import React from 'react';
import QRCode from 'qrcode.react';

const IMTSQRCode = () => {
    const reviewUrl = `https://qr.imtscollege.in/imts-review?businessName=IMTS`;

  return (
    <div className="qr-code-container">
      <QRCode value={reviewUrl} size={150} />
      <p>Scan to leave a review for IMTS Institute</p>
    </div>
  );
};

export default IMTSQRCode; */

// src/components/IMTSQRCode.js
import React from 'react';
import QRCode from 'qrcode.react';

const IMTSQRCode = () => {
    const reviewUrl = `https://qr.imtscollege.in/imts-review?businessName=IMTS`;

    return (
        <div className="qr-code-container">
            <a href={reviewUrl} target="_blank" rel="noopener noreferrer">
                <QRCode value={reviewUrl} size={150} />
            </a>
            <p>Scan or click to leave a review for IMTS Institute</p>
        </div>
    );
};

export default IMTSQRCode;


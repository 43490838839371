import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signup from './components/Signup';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import QRCodePage from './components/QRCodePage';
import ThreeStarReviewsPage from './components/ThreeStarReviewsPage';
import BelowThreeStarReviewsPage from './components/BelowThreeStarReviewsPage';
import ReviewForm from './components/ReviewForm';
import Header from './components/Header';
import WhyMagicCodes from './components/WhyMagicCodes';
import WhatMagicCodes from './components/WhatMagicCodes';
import MagicCodePerfect from './components/MagicCodePerfect';
import MagicCodesWork from './components/MagicCodesWork';
import WhatToDo from './components/WhatToDo';
import Bar from './components/bar';
import WelcomeBox from './components/WelcomeBox';
import IMTSReview from './components/IMTSReview';
import IMTSDashboard from './components/IMTSDashboard';
import IMTSAbove3 from './components/IMTSAbove3';
import IMTSBelow3 from './components/IMTSBelow3';
import ThankYouPage from './components/ThankYouPage';
import ProtectedRoute from './components/ProtectedRoute';
import IMTSAdminLogin from './components/IMTSAdminLogin';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/qr-code" element={<QRCodePage />} />
          <Route path="/3-star-reviews" element={<ThreeStarReviewsPage />} />
          <Route path="/below-3-star-reviews" element={<BelowThreeStarReviewsPage />} />
          <Route path="/review-form" element={<ReviewForm />} />
          <Route path="/imts-review" element={<IMTSReview />} />
          <Route path="/imts-dashboard" element={<ProtectedRoute element={<IMTSDashboard />} />} />
          <Route path="/imts-above-3" element={<ProtectedRoute element={<IMTSAbove3 />} />} />
          <Route path="/imts-below-3" element={<ProtectedRoute element={<IMTSBelow3 />} />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/imts-admin" element={<IMTSAdminLogin />} />
          <Route
            path="/"
            element={
              <>
                <WelcomeBox />
                <Header />
                <WhyMagicCodes />
                <WhatMagicCodes />
                <MagicCodePerfect />
                <MagicCodesWork />
                <WhatToDo />
                <Bar />
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

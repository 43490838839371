// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDocs, collection, addDoc} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyA4HaKHOxuGz0dbESS4KWrScqdkGdBa16k",
  authDomain: "review-handing.firebaseapp.com",
  projectId: "review-handing",
  storageBucket: "review-handing.appspot.com",
  messagingSenderId: "1000984110482",
  appId: "1:1000984110482:web:b87684fe691bb04b874d4b"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, getDocs, collection, addDoc };

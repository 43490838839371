import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styles/Header.css';

const Header = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleBuyNowClick = () => {
        navigate('/signup'); // Navigate to the signup page
    };

    return (
        <header className="header">
            <h1>Boost Your Business Growth with 5⭐ Google Reviews</h1>
            <h2>Say Goodbye to Negative Reviews Forever!</h2>
            <p className='tube'>Watch this video below to know more details of the offer</p>
            <div className="arrow">⬇⬇⬇⬇⬇⬇⬇⬇</div> {/* Arrow symbol */}
            <div className='linkk'>
                <iframe 
                    width="853" 
                    height="480" 
                    src="https://www.youtube.com/embed/-OaAvzVNK5A" 
                    title="Rank Your Business #1 on Google With Magic QR Codes" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin" 
                    allowFullScreen
                ></iframe>
            </div>

            <div>
                <p>✅ 1000X Frequency of Positive reviews | ✅ Reduce 98% of Negative Reviews</p>
                <p>❌ NO Expensive Paid Ads | ❌ NO Spam Calling Lifetime Deal</p>
                <p className='deal'>Lifetime Deal</p>
            </div>
            <div>
                <button className='buyy' onClick={handleBuyNowClick}>BUY NOW AT JUST <span className='fake'>₹299 </span>₹199</button>
            </div>
        </header>
    );
};

export default Header;

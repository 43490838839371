// src/components/IMTSDashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import '../styles/IMTSDashboard.css';

const IMTSDashboard = () => {
  const navigate = useNavigate();
  const [aboveThreeStarReviews, setAboveThreeStarReviews] = useState([]);
  const [belowThreeStarReviews, setBelowThreeStarReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsRef = collection(db, 'imtsReviews');

        // Fetch reviews above 3 stars
        const aboveThreeStarQuery = query(reviewsRef, where('rating', '>', 3));
        const aboveThreeStarSnapshot = await getDocs(aboveThreeStarQuery);
        const aboveThreeStarData = aboveThreeStarSnapshot.docs.map(doc => doc.data());

        // Fetch reviews below or equal to 3 stars
        const belowThreeStarQuery = query(reviewsRef, where('rating', '<=', 3));
        const belowThreeStarSnapshot = await getDocs(belowThreeStarQuery);
        const belowThreeStarData = belowThreeStarSnapshot.docs.map(doc => doc.data());

        setAboveThreeStarReviews(aboveThreeStarData);
        setBelowThreeStarReviews(belowThreeStarData);
      } catch (err) {
        setError('Error fetching reviews');
        console.error('Error fetching reviews: ', err);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="imts-dashboard-container">
      <h2>IMTS Reviews Dashboard</h2>
      <div className="review-section">
        <div
          className="review-box"
          onClick={() => navigate('/imts-above-3', { state: { reviews: aboveThreeStarReviews } })}
        >
          <h3>Reviews above 3 stars</h3>
          <p>{aboveThreeStarReviews.length} reviews</p>
        </div>
        <div
          className="review-box"
          onClick={() => navigate('/imts-below-3', { state: { reviews: belowThreeStarReviews } })}
        >
          <h3>Reviews below 3 stars</h3>
          <p>{belowThreeStarReviews.length} reviews</p>
        </div>
      </div>
    </div>
  );
};

export default IMTSDashboard;

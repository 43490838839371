import React from 'react';
import '../styles/WhyMagicCodes.css';
import Magic1 from '../assets/Magic1.png';
import Magic2 from '../assets/Magic2.png';
import Magic3 from '../assets/Magic3.png';
import Magic4 from '../assets/Magic4.png';
import Magic5 from '../assets/Magic5.png';

const WhyMagicCodes = () => {
  return (
    <div className='magic'>
        <h1>Why Magic QR Codes?</h1>
        <div className='card-container'>
            <div className='card'>
                <img src={Magic1} alt="Magic 1" className='card-image'/>
                <p>More Effective than Paid ads</p>
            </div>
            <div className='card'>
                <img src={Magic2} alt="Magic 2" className='card-image'/>
                <p>Get new customers Every Day</p>
            </div>
            <div className='card'>
                <img src={Magic3} alt="Magic 3" className='card-image'/>
                <p>Get repeat purchases from existing customers</p>
            </div>
            <div className='card'>
                <img src={Magic4} alt="Magic 4" className='card-image'/>
                <p>Build great & long-term Customer Experience</p>
            </div>
            <div className='card'>
                <img src={Magic5} alt="Magic 5" className='card-image'/>
                <p>100% Automate your Marketing Effort</p>
            </div>
        </div>
    </div>
  );
}

export default WhyMagicCodes;

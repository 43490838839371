// src/components/WelcomeBox.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import IMTSQRCode from './IMTSQRCode';
import '../styles/WelcomeBox.css'; // Create this CSS file to style the box

const WelcomeBox = () => {
  const navigate = useNavigate();

  const handleRateUsClick = () => {
    navigate('/imts-review');
  };

  return (
    <div className="welcome-box">
      <h1>Welcome to IMTS INSTITUTE</h1>
      <button className="rate-us-button" onClick={handleRateUsClick}>
        Rate us
      </button>
      <p>We value your feedback. Please leave a review!</p>
      <IMTSQRCode />
    </div>
  );
};

export default WelcomeBox;

import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styles/MagicCodesWork.css';

const MagicCodesWork = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleBuyNowClick = () => {
        navigate('/signup'); // Navigate to the signup page
    };

    return (
        <section className="magic-codes-work">
            <h1>How these Magic QR Codes Work?</h1>
            <ul className="timeline">
                <li>
                    <div>Place the Magic QR Code on your Business Premise/Order Box</div>
                </li>
                <li>
                    <div>Customers Scan the QR Code and add the reviews</div>
                </li>
                <li>
                    <div>If it’s a positive review, it goes directly to your Google listing’s reviews</div>
                </li>
                <li>
                    <div>If it’s a negative review, it remains on the Magic QR Code’s Dashboard which you can review later</div>
                </li>
                <li>
                    <div>See Revenue Growing Day By Day</div>
                </li>
            </ul>

            <button className='buyyyy' onClick={handleBuyNowClick}> BUY NOW AT JUST <span className='fake'>₹299 </span>₹199</button>
        </section>
    );
};

export default MagicCodesWork;

// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ element: Element }) => {
  const isAuthenticated = Cookies.get('isAuthenticated');

  return isAuthenticated ? Element : <Navigate to="/imts-admin" />;
};

export default ProtectedRoute;

import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import '../styles/IMTSBelow3.css';

const IMTSBelow3 = () => {
  const [reviews, setReviews] = useState([]);
  const [sortOption, setSortOption] = useState('default'); // Default option

  useEffect(() => {
    const fetchReviews = async () => {
      const q = query(collection(db, 'imtsReviews'), where('rating', '<=', 3));
      const querySnapshot = await getDocs(q);
      const fetchedReviews = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setReviews(fetchedReviews);
    };

    fetchReviews();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'imtsReviews', id));
      setReviews(reviews.filter(review => review.id !== id));
    } catch (error) {
      console.error('Error deleting review: ', error);
    }
  };

  const sortReviews = (reviews, option) => {
    if (option === 'default') {
      return reviews; // No sorting applied
    } else if (option === 'rating') {
      return [...reviews].sort((a, b) => b.rating - a.rating);
    } else if (option === 'date') {
      return [...reviews].sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());
    }
    return reviews;
  };

  return (
    <div className="reviews-container">
      <h2>Reviews Below or Equal to 3 Stars</h2>
      <div className="sort-container">
        <label htmlFor="sort">Sort by:</label>
        <select id="sort" value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
          <option value="default">Default</option>
          <option value="rating">Rating</option>
          <option value="date">Date</option>
        </select>
      </div>
      {reviews.length > 0 ? (
        <table className="reviews-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Rating</th>
              <th>Review</th>
              <th>Date</th>
              <th>Phone No.</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {sortReviews(reviews, sortOption).map((review) => (
              <tr key={review.id}>
                <td>{review.name}</td>
                <td>{review.rating}</td>
                <td>{review.review}</td>
                <td>{new Date(review.timestamp.toDate()).toLocaleString()}</td>
                <td>{review.contact}</td>
                <td>
                  <button onClick={() => handleDelete(review.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No reviews with ratings below or equal to 3 stars.</p>
      )}
    </div>
  );
};

export default IMTSBelow3;

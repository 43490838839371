// src/components/ReviewForm.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, addDoc, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import '../styles/ReviewForm.css';

const ReviewForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const businessName = queryParams.get('businessName') || '';

  const [rating, setRating] = useState(0);
  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const [review, setReview] = useState('');
  const [businessUrl, setBusinessUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBusinessUrl = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setBusinessUrl(userData.businessUrl);
          }
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching business URL: ', error);
        setLoading(false);
      }
    };

    fetchBusinessUrl();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'reviews'), {
        businessName,
        rating,
        name,
        contact,
        review,
        timestamp: new Date(),
      });
      
      // Fetch the latest review to determine the action
      const reviewsQuery = query(collection(db, 'reviews'), where('businessName', '==', businessName));
      const querySnapshot = await getDocs(reviewsQuery);

      let redirectToUrl = false;
      querySnapshot.forEach((doc) => {
        const reviewData = doc.data();
        if (reviewData.rating > 3) {
          redirectToUrl = true;
        }
      });

      if (redirectToUrl && businessUrl) {
        // Redirect to the fetched business URL
        window.location.href = businessUrl;
      } else {
        navigate('/below-three-star-reviews'); // Redirect to below 3 star reviews page
      }

    } catch (error) {
      console.error('Error submitting review: ', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="review-form-container">
      <h2>Review {businessName}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="rating">Rating:</label>
          <div className="rating-container">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                className={`star ${rating >= star ? 'filled' : ''}`}
                onClick={() => setRating(star)}
              >
                &#9733;
              </span>
            ))}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="review">
            Tell us about your experience with {businessName}:
          </label>
          <textarea
            id="review"
            value={review}
            onChange={(e) => setReview(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Your Name:</label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="contact">Contact No:</label>
          <input
            id="contact"
            type="text"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default ReviewForm;

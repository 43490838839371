import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styles/WhatMagicCodes.css';

const WhatMagicCodes = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleBuyNowClick = () => {
    navigate('/signup'); // Navigate to the signup page
  };

  return (
    <div className='qrcodes'>
      <h1>What are Magic QR Codes?</h1>
      <p className='answer'>MBG is an Automation System that helps all types of businesses get new customers, repeat orders & scale revenue every month</p>
      <div className='tick'>
        <p className='ticks'> ✅Get new Customers every day.</p>
        <p className='ticks'> ✅Retain customers & get repeat purchases.</p>
        <p className='ticks'> ✅Convert any Leads to Sale faster.</p>
        <p className='ticks'> ✅Build great Customer Experience.</p>
        <p className='ticks'> ✅Automate your all Marketing including Whatsapp, Instagram, Youtube & Many.</p>
        <p className='ticks'> ✅Get a top Google Business Search Ranking with high customer ratings & reviews.</p>
      </div>

      <p className='ok'>All of these are On Automation!</p>

      <button className='buyyy' onClick={handleBuyNowClick}> BUY NOW AT JUST <span className='fake'>₹299 </span>₹199</button>
    </div>
  );
}

export default WhatMagicCodes;

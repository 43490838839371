import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styles/WhatToDo.css';
import todo1 from '../assets/todo1.png';
import todo2 from '../assets/todo2.png';
import todo3 from '../assets/todo3.png';

const WhatToDo = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleBuyNowClick = () => {
    navigate('/signup'); // Navigate to the signup page
  };

  return (
    <section className='what-to-do'>
      <h1>What You Have to Do?</h1>
      <p className='intro-text'>
        Just show these 2 QR codes to your Customers, that’s it! We will take care of everything!
        So that your business grows rapidly!
      </p>
      <div className='todo-container'>
        <div className='todo-card'>
          <img src={todo1} alt='Zero Computer Skills Needed' className='todo-img' />
          <p>Zero Computer Skills Needed</p>
        </div>

        <div className='todo-card'>
          <img src={todo2} alt='Works For Every Type Of Business' className='todo-img' />
          <p>Works For Every Type Of Business</p>
        </div>

        <div className='todo-card'>
          <img src={todo3} alt='Market, Sell, And Grow Like A Pro' className='todo-img' />
          <p>Market, Sell, And Grow Like A Pro</p>
        </div>
      </div>

      <p className='extra'>Just Sit and Watch Your Business Growing</p>
      <button className='buyyyy' onClick={handleBuyNowClick}> BUY NOW AT JUST ₹199</button>
    </section>
  );
};

export default WhatToDo;

// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Ensure this path is correct
import '../styles/Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const [businessData, setBusinessData] = useState({
    businessName: '',
    businessUrl: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchBusinessData = async () => {
      const user = auth.currentUser; // Get current user
      if (user) {
        const userId = user.uid; // Get user ID
        const userDoc = doc(db, 'users', userId);
        try {
          const docSnap = await getDoc(userDoc);
          if (docSnap.exists()) {
            setBusinessData(docSnap.data());
          } else {
            console.log('No such document!');
          }
        } catch (err) {
          setError('Error fetching user data');
          console.error('Error fetching user data: ', err);
        }
      } else {
        setError('User not authenticated');
      }
      setLoading(false);
    };

    fetchBusinessData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Ensure you use the local development URL here
  const reviewFormUrl = `https://qr.imtscollege.in/review-form?businessName=${encodeURIComponent(businessData.businessName)}&businessUrl=${encodeURIComponent(businessData.businessUrl)}`;

  return (
    <div className="dashboard-container">
      <h2>Welcome to the User Dashboard</h2>
      <div className="dashboard-boxes">
        <div className="dashboard-box" onClick={() => navigate('/qr-code', { state: { reviewFormUrl } })}>
          <h3>QR Code</h3>
        </div>
        <div className="dashboard-box" onClick={() => navigate('/3-star-reviews')}>
          <h3>3+ Star Reviews</h3>
        </div>
        <div className="dashboard-box" onClick={() => navigate('/below-3-star-reviews')}>
          <h3>Reviews below 3 stars</h3>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

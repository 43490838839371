// src/components/BelowThreeStarReviewsPage.js
import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import '../styles/BelowThreeStarReviewsPage.css';

const BelowThreeStarReviewsPage = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      const q = query(collection(db, 'reviews'), where('rating', '<=', 3));
      const querySnapshot = await getDocs(q);
      const fetchedReviews = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setReviews(fetchedReviews);
    };

    fetchReviews();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'reviews', id));
      setReviews(reviews.filter(review => review.id !== id));
    } catch (error) {
      console.error('Error deleting review: ', error);
    }
  };

  return (
    <div className="reviews-container">
      <h2>Reviews Below 3 Stars</h2>
      {reviews.length > 0 ? (
        <table className="reviews-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Rating</th>
              <th>Review</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {reviews.map((review) => (
              <tr key={review.id}>
                <td>{review.name}</td>
                <td>{review.rating}</td>
                <td>{review.review}</td>
                <td>{new Date(review.timestamp.toDate()).toLocaleString()}</td>
                <td>
                  <button onClick={() => handleDelete(review.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No reviews below 3 stars.</p>
      )}
    </div>
  );
};

export default BelowThreeStarReviewsPage;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styles/bar.css';

const Bar = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const now = new Date();
    const nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0); // Next midnight

    return nextMidnight - now;
  }

  function formatTime(ms) {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }

  const handleBuyNowClick = () => {
    navigate('/signup'); // Navigate to the signup page
  };

  return (
    <div className='bar'>
      <div className='price'>
        <span className='amount'><span className='fake'>₹299</span>₹199</span>
        <div className='timer'>Expires in: {formatTime(timeLeft)}</div>
      </div>
      <button className='buyyyy' onClick={handleBuyNowClick}>BUY NOW AT JUST <span className='fake'>₹299 </span>₹199</button>
    </div>
  );
};

export default Bar;

// src/components/ThankYouPage.js
import React from 'react';

const ThankYouPage = () => {
  return (
    <div className="thank-you-container">
      <h2>Thank You!</h2>
      <p>Your feedback is very important to us.</p>
    </div>
  );
};

export default ThankYouPage;

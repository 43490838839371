// src/components/QRCodePage.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import QRCode from 'qrcode.react';
import '../styles/QRCodePage.css';

const QRCodePage = () => {
  const location = useLocation();
  const { reviewFormUrl } = location.state || {};

  if (!reviewFormUrl) {
    return <div>Invalid QR Code request.</div>;
  }

  return (
    <div className="qr-code-container">
      <h2>QR Code</h2>
      <QRCode value={reviewFormUrl} size={256} />
      <p>Scan this QR code to access the review form.</p>
    </div>
  );
};

export default QRCodePage;

import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styles/MagicCodePerfect.css';
import perfect1 from '../assets/perfect1.png';
import perfect2 from '../assets/perfect2.png';
import perfect3 from '../assets/perfect3.png';
import perfect4 from '../assets/perfect4.png';
import perfect5 from '../assets/perfect5.png';
import perfect6 from '../assets/perfect6.png';
import perfect7 from '../assets/perfect7.png';
import perfect8 from '../assets/perfect8.png';

const MagicCodePerfect = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleBuyNowClick = () => {
    navigate('/signup'); // Navigate to the signup page
  };

  return (
    <div className='magic-perfect'>
      <h1>Magic QR Code Is Perfect For</h1>
      <div className='card-container'>
        <div className='card'>
          <img src={perfect1} alt='Small Business Owners' className='card-img' />
          <p>Small Business Owners</p>
        </div>
        <div className='card'>
          <img src={perfect2} alt='Hotel and Restaurant Owners' className='card-img' />
          <p>Hotel and Restaurant Owners</p>
        </div>
        <div className='card'>
          <img src={perfect3} alt='Coaches & Consultants' className='card-img' />
          <p>Coaches & Consultants</p>
        </div>
        <div className='card'>
          <img src={perfect4} alt='E-commerce Owners' className='card-img' />
          <p>E-commerce Owners</p>
        </div>
        <div className='card'>
          <img src={perfect5} alt='Solopreneurs / Self-employed' className='card-img' />
          <p>Solopreneurs / Self-employed</p>
        </div>
        <div className='card'>
          <img src={perfect6} alt='Makeup Artists & Bakers' className='card-img' />
          <p>Makeup Artists & Bakers</p>
        </div>
        <div className='card'>
          <img src={perfect7} alt='Anyone who wants to grow their business' className='card-img' />
          <p>Anyone who wants to grow their business</p>
        </div>
        <div className='card'>
          <img src={perfect8} alt='Real Estate Owners & Agents' className='card-img' />
          <p>Real Estate Owners & Agents</p>
        </div>
      </div>
      <button className='buyyyy' onClick={handleBuyNowClick}> BUY NOW AT JUST <span className='fake'>₹299 </span> ₹199</button>
    </div>
  );
}

export default MagicCodePerfect;
